
import { httpGet } from '@/api';
import { computed, reactive, ref, toRefs } from 'vue';
import { errorMessage, getRouterParams } from '@/utils/publicMethods';
import router from '@/router';
import BaseInfo from './components/BaseInfo/BaseInfo.vue';
import XsContacts from './components/XsContacts/index.vue';
import DzContacts from './components/DzContacts/index.vue';
import useVisible from '@/hooks/useVisible';
import Attachment from './components/Attachment/index.vue';
import EpidAttachment from './components/EpidAttachment/index.vue';

export default {
  name: 'SupplierDetails',
  components: {
    BaseInfo,
    XsContacts,
    DzContacts,
    Attachment,
    EpidAttachment,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id, type: paramType, backFrom } = getRouterParams('SupplierDetails');
    console.log('', getRouterParams('SupplierDetails'));
    // 当前列表状态
    const type = ref(paramType as number);
    const detailsData = ref();
    const load = ref(true);
    const chooseRef = ref();
    /** 编辑线索展示 */
    const { visible, showVisible } = useVisible();

    const leftTitleNames = reactive({
      xsContactsTitle: '销售联系人',
      dzContactsTitle: '单证联系人',
      attachmentTitle: '附件',
      epidAttachmentTitle: '防疫',
    });
    // 是否能编辑
    const isHidden = computed(() => {
      return type.value > 1;
    });
    const scoreName = computed(() => {
      return detailsData.value?.customerName;
    });

    // 初始化数据
    const initData = async (id) => {
      load.value = false;
      const res = await httpGet(`malicrm/supplier/supplierDetail/${id}`);
      if (res.code === 200) {
        detailsData.value = res.result;
        load.value = true;
      } else {
        // 这里传个12什么意思
        if (res.code === 12) {
          // todo 这里也不知道这里作什么的，到时候调查下
        } else {
          errorMessage(res.message);
        }
      }
    };
    const closeVisible = (status) => {
      visible.value = false;
      if (status) {
        initData(id);
      }
    };
    // 设置标题方法
    const getXsContactsTitle = (str) => {
      leftTitleNames.xsContactsTitle = str;
    };
    const getDzContactsTitleTitle = (str) => {
      leftTitleNames.dzContactsTitle = str;
    };
    const getAttachmentTitle = (str) => {
      leftTitleNames.attachmentTitle = str;
    };
    const getEpidAttachmentTitle = (str) => {
      leftTitleNames.epidAttachmentTitle = str;
    };
    // 返回操作
    const back = () => {
      if (backFrom) {
        router.push(backFrom);
      }
      // TODO 改造成 from判断
    };
    initData(id);
    return {
      load,
      id,
      type,
      isHidden,
      detailsData,
      back,
      ...toRefs(leftTitleNames),
      getXsContactsTitle,
      getDzContactsTitleTitle,
      getAttachmentTitle,
      getEpidAttachmentTitle,
      chooseRef,
      scoreName,
      visible,
      showVisible,
      closeVisible,
    };
  },
};
