
import { httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import { useStore } from '@/store';
import { ajaxLoading, errorMessage, successMessage, setTitleLabel } from '@/utils/publicMethods';

import { computed, defineComponent, PropType, ref } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: { FileContent },
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
    },
    /** 事件id  */
    eventId: {
      type: [Number, String],
    },
    /** 事件状态 1.线索 2.客户 3.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    /** 事件状态 1.线索 2.客户 3.商机 */
    data: {
      type: Object as PropType<any>,
    },
    scoreName: {
      type: String as PropType<string>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const { rules, formData, formRef } = useFormData();
    const store = useStore();
    const dictOptions = computed(() => {
      return store.state.dict?.options;
    });
    const tips = ref('');
    const fileType = ref('');
    const maxImg = ref(1);
    const size = ref(30);
    const isMessag = ref(true);
    const fileUrlsArr = ref<any[]>([]);
    const changeType = (item) => {
      if (item.value === 21) {
        tips.value = '允许类型：pdf,doc,docx     大小上限：30M  此类文件最多上传1个';
        fileType.value = 'supplierFile';
        maxImg.value = 1;
        size.value = 30;
      }
      if (item.value === 22) {
        tips.value = '允许类型：jpg,png,gif     大小上限：30M  此类文件最多上传10个';
        fileType.value = 'supplierImg';
        maxImg.value = 10;
        size.value = 30;
      }
      if (item.value === 23) {
        tips.value = '允许类型：mp4     大小上限：300M  此类文件最多上传3个';
        fileType.value = 'video';
        maxImg.value = 3;
        size.value = 300;
      }
    };
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      console.log('fileList', fileList);
      if (fileList.length > 0) {
        fileUrlsArr.value = fileList;
        isMessag.value = false;
      } else {
        formData.attachmentDtos = null;
        isMessag.value = true;
      }
      console.log('isMessag.value', isMessag.value);
      formRef.value.validate();
    };
    const getVideoFileUrls = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.shortName = fileList[0].name;
        formData.shortUrl = fileList[0].url;
      }
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    /** 提交表单 */
    const saveFormData = async () => {
      if (!isMessag.value) {
        ajaxLoading.lock();
        formData.attachmentDtos = [];
        const params = {
          attachmentDtos: formData.attachmentDtos,
          attachmentType: (formData.attachmentType as any).value,
          id: props.eventId,
        };
        fileUrlsArr.value.forEach((v) => {
          (params.attachmentDtos as any).push({
            name: v.name,
            url: v.url,
            shortName: v.name,
            shortUrl: v.url,
          });
        });
        if (formData.attachmentType === 23) {
          (params.attachmentDtos as any).push({
            name: formData.shortName,
            url: formData.shortUrl,
          });
        }
        const res = await httpPost('/malicrm/supplier/addInspectAttachment', params);
        ajaxLoading.unLock();
        if (res.code === 200) {
          if (props.eventType === 5) {
            successMessage('保存成功', 2000);
          } else {
            successMessage('添加成功', 2000);
          }
          close(true);
        } else {
          errorMessage(res.message);
        }
      } else {
        errorMessage('请添加要上传的附件！');
      }
    };
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      getFileUrls,
      fileUrlsArr,
      getVideoFileUrls,
      dictOptions,
      isMessag,
      tips,
      fileType,
      maxImg,
      size,
      changeType,
    };
  },
});
