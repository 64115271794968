import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03843cdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "flex-box" }
const _hoisted_3 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['新建', '编辑', '查看']) + '单证联系人',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.formType === 3 ? {} : _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "联系人",
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_el_input, {
                            onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getName($event, 'name'))),
                            modelValue: _ctx.formData.name,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.name) = $event)),
                            placeholder: _ctx.formType === 3 ? '-' : '请输入联系人',
                            maxlength: 30,
                            clearable: "",
                            disabled: _ctx.formType === 3
                          }, null, 8, ["modelValue", "placeholder", "disabled"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "职位",
                      prop: "position"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: _ctx.formType === 3,
                          clearable: "",
                          modelValue: _ctx.formData.position,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.position) = $event)),
                          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getName($event, 'pos'))),
                          placeholder: _ctx.formType === 3 ? '-' : '请输入职位',
                          maxlength: 30
                        }, null, 8, ["disabled", "modelValue", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "联系电话",
                      prop: "telephone"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: _ctx.formType === 3,
                          modelValue: _ctx.formData.telephone,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.telephone) = $event)),
                          clearable: "",
                          onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getName($event, 'tel'))),
                          placeholder: _ctx.formType === 3 ? '-' : '请输入联系电话',
                          maxlength: 30
                        }, null, 8, ["disabled", "modelValue", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "微信",
                      prop: "weixin"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: _ctx.formType === 3,
                          clearable: "",
                          modelValue: _ctx.formData.weixin,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.weixin) = $event)),
                          onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getName($event, 'wei'))),
                          placeholder: _ctx.formType === 3 ? '-' : '请输入微信',
                          maxlength: 30
                        }, null, 8, ["disabled", "modelValue", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "邮箱",
                      prop: "email"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: _ctx.formType === 3,
                          modelValue: _ctx.formData.email,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.email) = $event)),
                          clearable: "",
                          onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.getName($event, 'eml'))),
                          placeholder: _ctx.formType === 3 ? '-' : '请输入邮箱',
                          maxlength: 50
                        }, null, 8, ["disabled", "modelValue", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.formType < 3)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "crm-form__button primary",
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
            }, "保存"))
          : _createCommentVNode("", true),
        (_ctx.formType === 2)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "crm-form__button cancel",
              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "取消"))
          : _createCommentVNode("", true),
        (_ctx.formType > 2)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "crm-form__button primary",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "关闭"))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}