import { reactive, ref } from 'vue';

export default () => {
  const formRef = ref(); // 表单校验对象使用
  type FormData = {
    id: string | null;
    shortName: string | null;
    shortUrl: string | null;
    attachmentType: string | null | number;
    attachmentDtos: any[] | null;
  };
  const formData: FormData = reactive({
    id: null,
    shortUrl: null,
    shortName: null,
    attachmentType: null,
    attachmentDtos: null,
  });
  const rules = reactive({
    attachmentDtos: [
      {
        required: true,
        message: '请上传附件',
        trigger: 'change',
      },
    ],
  });

  return { rules, formData, formRef };
};
