import { reactive, ref } from 'vue';
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    contactsType: string;
    email: string; // 邮箱
    id: string | null;
    name: string; // 姓名
    position: string; // 职位
    supplierId: string | number | undefined;
    telephone: string;
    weixin: string;
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    contactsType: '2',
    email: '',
    id: '',
    name: '',
    position: '',
    supplierId: '',
    telephone: '',
    version: '',
    weixin: '',
    ...params,
  });

  const rules = reactive({
    name: [
      {
        required: true,
        message: '请输入联系人',
        trigger: 'blur',
      },
    ],
    weixin: [
      {
        max: 30,
        message: '最大长度为30',
        trigger: 'change',
      },
    ],
    email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
  });

  return { rules, formData, formRef };
};
