import { reactive, ref } from 'vue';
const checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入联系电话'));
  }
  setTimeout(() => {
    // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
    // 所以我就在前面加了一个+实现隐式转换
    if (/[\u4E00-\u9FA5]/g.test(value)) {
      callback(new Error('电话号码格式不正确'));
    } else {
      callback();
    }
  }, 100);
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    address: string;
    contactsType: string;
    email: string; // 邮箱
    id: string | null;
    name: string; // 姓名
    position: string; // 职位
    supplierId: string | number | undefined;
    telephone: string;
    weixin: string;
    remark?: string;
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    address: '',
    contactsType: '1',
    email: '',
    id: null,
    name: '',
    position: '',
    supplierId: '',
    telephone: '',
    weixin: '',
    ...params,
  });

  const rules = reactive({
    name: [
      {
        required: true,
        message: '请输入联系人',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: '请输入邮箱',
        trigger: 'blur',
      },
    ],
    telephone: [
      {
        validator: checkPhone,
        trigger: 'blur',
      },
      {
        required: true,
        max: 30,
        message: '最大长度为30',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef };
};
